<template>
  <div>
    <v-simple-table
      v-if="campaignModel"
      class="campaign-table"
      dense
    >
      <template v-slot:default>
        <thead>
          <tr>
            <th
              v-for="header in campaignHeaders"
              :key="header.value"
              class="text-left"
            >
              {{ header.text }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {{ campaignModel.campaignName }}
            </td>
            <td>
              {{ campaignModel.onBehalfOfName }}
            </td>
            <td>
              <v-chip
                :color="$_dashMixin_campaignStatusColor(campaignModel)"
                light
              >
                {{ campaignModel.campaignStatusDisplay }}
              </v-chip>
            </td>
            <td>
              {{ campaignModel.modifiedOnLocal | displayDateTimeFormat }}
            </td>
            <td>
              {{ campaignModel.modifiedBy }}
            </td>
            <td>
              <v-tooltip
                top
              >
                <template v-slot:activator="{ on }">
                  <v-icon
                    :color="$_dashMixin_taStatusColor(campaignModel.targetAudienceStatus)"
                    left
                    v-on="on"
                  >
                    {{ $_dashMixin_getTAIcon(campaignModel.targetAudienceStatus) }}
                  </v-icon>
                </template>
                <span>{{ campaignModel.targetAudienceStatusDisplay || 'Not selected' }}</span>
              </v-tooltip>
            </td>
            <td>
              <v-tooltip
                v-for="(mailing, i) in campaignModel.mailings"
                :key="i"
                top
              >
                <template v-slot:activator="{ on }">
                  <v-icon
                    :color="$_dashMixin_getMailingIconColor(mailing)"
                    left
                    v-on="on"
                  >
                    {{ $_dashMixin_getMailingIcon(mailing) }}
                  </v-icon>
                </template>
                <span>{{ mailing.statusDisplay }}</span>
              </v-tooltip>
              <template v-if="campaignModel.numberOfBroadcasts >= campaignModel.mailings.length && !campaignModel.campaignExpired">
                <v-tooltip
                  v-for="i in campaignModel.numberOfBroadcasts - campaignModel.mailings.length"
                  :key="`empty-${i}`"
                  top
                >
                  <template v-slot:activator="{ on }">
                    <v-icon
                      color="grey"
                      left
                      v-on="on"
                    >
                      mdi-email-outline
                    </v-icon>
                  </template>
                  <span>Not Created</span>
                </v-tooltip>
              </template>
            </td>
            <td>
              <template v-if="!campaignModel.readOnlyActions">
                <v-tooltip
                  top
                >
                  <template v-slot:activator="{ on }">
                    <v-icon
                      :disabled="!campaignModel.canAddMailings"
                      class="mr-2"
                      color="success"
                      @click="$_dashMixin_addMailing(campaignModel)"
                      v-on="on"
                    >
                      mdi-email-plus
                    </v-icon>
                  </template>
                  <span>Add Mailing</span>
                </v-tooltip>
                <v-menu
                  right
                  x-offset
                  origin="right"
                  transition="slide-x-transition"
                >
                  <template v-slot:activator="{ on: onMenu }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on: onTooltip }">
                        <v-icon
                          :disabled="campaignModel.campaignStatus !== 1"
                          class="mr-2"
                          color="error"
                          v-on="{ ...onMenu, ...onTooltip }"
                        >
                          mdi-close
                        </v-icon>
                      </template>
                      <span>Cancel Campaign</span>
                    </v-tooltip>
                  </template>
                  <v-card>
                    <v-card-text class="pa-5">
                      <span>Are you sure you want to cancel this campaign? No scheduled mailings will be sent.</span>
                    </v-card-text>
                    <v-divider />
                    <v-card-actions>
                      <v-spacer />
                      <v-btn
                        color="tertiary"
                        :disabled="isLoading()"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        color="error"
                        :loading="isLoading()"
                        @click="$_dashMixin_cancelCampaign(campaignModel)"
                      >
                        Yes
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-menu>
                <v-tooltip
                  top
                >
                  <template v-slot:activator="{ on }">
                    <v-icon
                      class="mr-2"
                      color="info"
                      @click="$_dashMixin_copyCampaign(campaignModel)"
                      v-on="on"
                    >
                      mdi-content-copy
                    </v-icon>
                  </template>
                  <span>Copy Campaign</span>
                </v-tooltip>
              </template>
              <v-tooltip
                top
              >
                <template v-slot:activator="{ on }">
                  <v-icon
                    class="mr-2"
                    color="info"
                    @click="$_dashMixin_campaignHistory(campaignModel.campaignId)"
                    v-on="on"
                  >
                    mdi-book-open-page-variant
                  </v-icon>
                </template>
                <span>Campaign History</span>
              </v-tooltip>
              <v-tooltip
                v-if="campaignModel.campaignStatus === $_campaignStatus_Completed && campaignModel.campaignFinished && ($_global_hasRouteClaims('CampaignEventsHeatmap') || $_global_hasRouteClaims('CampaignReport'))"
                top
              >
                <template v-slot:activator="{ on: onTooltip }">
                  <v-menu offset-y>
                    <template v-slot:activator="{ on: onMenu, attrs }">
                      <v-icon
                        class="mr-2"
                        color="info"
                        v-bind="attrs"
                        v-on="{ ...onTooltip, ...onMenu }"
                      >
                        mdi-chart-box
                      </v-icon>
                    </template>
                    <v-list>
                      <v-list-item v-if="$_global_hasRouteClaims('CampaignEventsHeatmap') && $_dashMixin_isGeoDataFileTypeId(campaignModel.targetAudienceFileTypeId)">
                        <v-list-item-title>
                          <router-link :to="{ name: 'CampaignEventsHeatmap', params: { campaignId: campaignModel.campaignId } }">
                            Campaign Heatmap
                          </router-link>
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item v-if="$_global_hasRouteClaims('CampaignReport')">
                        <v-list-item-title>
                          <router-link :to="{ name: 'CampaignReport', params: { campaignId: campaignModel.campaignId } }">
                            Campaign Report
                          </router-link>
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template>
                <span>Reporting</span>
              </v-tooltip>
              <v-tooltip
                v-if="isMMSAdmin"
                top
              >
                <template v-slot:activator="{ on: onTooltip }">
                  <v-menu
                    v-model="campaignModel.adminDetailsMenu"
                    right
                    x-offset
                    origin="right"
                    transition="slide-x-transition"
                    :close-on-content-click="false"
                    :close-on-click="false"
                  >
                    <template v-slot:activator="{ on: onMenu }">
                      <v-icon
                        color="info"
                        class="mr-2"
                        v-on="{ ...onMenu, ...onTooltip }"
                      >
                        mdi-information
                      </v-icon>
                    </template>
                    <v-card>
                      <v-card-text class="px-5 py-2">
                        <LabelValue
                          label="Campaign Id"
                          :value="campaignModel.campaignId"
                        />
                        <LabelValue
                          label="Target Id"
                          :value="campaignModel.targetAudienceId"
                        />
                        <v-divider />
                        <div
                          v-for="(mailing, i) in campaignModel.mailings"
                          :key="`${mailing.id}-${i}`"
                        >
                          <LabelValue
                            :label="`Mailing Id ${mailing.sequence}`"
                            :value="mailing.id"
                          />
                          <LabelValue
                            v-for="(split, s) in mailing.splits"
                            :key="split.id"
                            :label="`Split Id ${$_splitMixin_splitLetters[s]}`"
                            :value="split.id"
                          />
                          <v-divider />
                        </div>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer />
                        <v-btn
                          color="tertiary"
                          @click="campaignModel.adminDetailsMenu = false"
                        >
                          Close
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-menu>
                </template>
                <span>Admin Details</span>
              </v-tooltip>
            </td>
          </tr>
          <tr>
            <td
              :colspan="campaignHeaders.length"
              class="px-0"
            >
              <CampaignDashboardExpanded
                :campaign="campaignModel"
                :current-mailing-id="currentMailingId"
                :loading="campaignLoading"
                :hide-target-data="hideTargetData"
              />
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-skeleton-loader
      v-else
      type="table"
    />
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { dashboardMixin } from '@/shared/mixins/mailing'
import { enumsMixin } from '@/shared/mixins/general'
import { mailingService } from '@/shared/services'
import moment from 'moment'
import claims from '@/shared/models/auth/claims'

export default {
  name: 'CampaignDashboardItem',

  components: {
    CampaignDashboardExpanded: () => import('@/views/pages/components/mailing/CampaignDashboardExpanded'),
    LabelValue: () => import('@/components/custom/LabelValue')
  },

  mixins: [dashboardMixin, enumsMixin],

  props: {
    campaign: {
      type: Object,
      default: () => {}
    },
    campaignId: {
      type: String,
      default: ''
    },
    currentMailingId: {
      type: String,
      required: false,
      default: ''
    },
    hideTargetData: {
      type: Boolean,
      default: false,
      required: false
    }
  },

  data () {
    return {
      campaignLoading: false,
      campaignObj: null,
      campaignHeaders: [
        {
          text: 'Campaign',
          value: 'campaignName'
        },
        {
          text: 'Contact',
          value: 'onBehalfOfName'
        },
        {
          text: 'Status',
          value: 'campaignStatusDisplay'
        },
        {
          text: 'Modified',
          value: 'modifiedOnLocal'
        },
        {
          text: 'Modified By',
          value: 'modifiedBy'
        },
        {
          text: 'Target',
          value: 'targetAudienceStatus'
        },
        {
          text: 'Mailings',
          value: 'mailings'
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          filterable: false
        }
      ]
    }
  },

  computed: {
    ...mapGetters(['isLoading']),
    ...mapGetters('auth', ['hasClaimKV']),
    campaignModel () {
      return this.campaign || this.campaignObj
    },
    isMMSAdmin () {
      return this.hasClaimKV(claims.MMS_TM)
    }
  },

  created () {
    if (this.campaignId && !this.campaign) {
      this.loadCampaign()
    }
  },

  methods: {
    async loadCampaign () {
      this.campaignLoading = true
      var resp = await mailingService.getCampaignDashboardItem(this.campaignId || this.getCampaignId())
      resp.modifiedOnLocal = moment(this.$_global_parseAsUtc(resp.modifiedOn)).local()
      Vue.set(this, 'campaignObj', resp)
      this.campaignLoading = false
    }
  }
}
</script>

<style lang="scss" scoped>
.v-data-table.campaign-table.theme--light {
  background-color: #fafafa;
}
.v-data-table.campaign-table.theme--dark {
  background-color: #424242;
}
.v-data-table.campaign-table {
  border-left-width: 5px;
  border-top-left-radius: 0%;
  border-bottom-left-radius: 0%;
  border-left-color: #f47837;
  border-left-style: solid;
}
</style>
