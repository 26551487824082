var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.campaignModel
        ? _c("v-simple-table", {
            staticClass: "campaign-table",
            attrs: { dense: "" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function() {
                    return [
                      _c("thead", [
                        _c(
                          "tr",
                          _vm._l(_vm.campaignHeaders, function(header) {
                            return _c(
                              "th",
                              { key: header.value, staticClass: "text-left" },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(header.text) +
                                    "\n          "
                                )
                              ]
                            )
                          }),
                          0
                        )
                      ]),
                      _c("tbody", [
                        _c("tr", [
                          _c("td", [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.campaignModel.campaignName) +
                                "\n          "
                            )
                          ]),
                          _c("td", [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.campaignModel.onBehalfOfName) +
                                "\n          "
                            )
                          ]),
                          _c(
                            "td",
                            [
                              _c(
                                "v-chip",
                                {
                                  attrs: {
                                    color: _vm.$_dashMixin_campaignStatusColor(
                                      _vm.campaignModel
                                    ),
                                    light: ""
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.campaignModel.campaignStatusDisplay
                                      ) +
                                      "\n            "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c("td", [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm._f("displayDateTimeFormat")(
                                    _vm.campaignModel.modifiedOnLocal
                                  )
                                ) +
                                "\n          "
                            )
                          ]),
                          _c("td", [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.campaignModel.modifiedBy) +
                                "\n          "
                            )
                          ]),
                          _c(
                            "td",
                            [
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { top: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          return [
                                            _c(
                                              "v-icon",
                                              _vm._g(
                                                {
                                                  attrs: {
                                                    color: _vm.$_dashMixin_taStatusColor(
                                                      _vm.campaignModel
                                                        .targetAudienceStatus
                                                    ),
                                                    left: ""
                                                  }
                                                },
                                                on
                                              ),
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      _vm.$_dashMixin_getTAIcon(
                                                        _vm.campaignModel
                                                          .targetAudienceStatus
                                                      )
                                                    ) +
                                                    "\n                "
                                                )
                                              ]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    3232349953
                                  )
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.campaignModel
                                          .targetAudienceStatusDisplay ||
                                          "Not selected"
                                      )
                                    )
                                  ])
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "td",
                            [
                              _vm._l(_vm.campaignModel.mailings, function(
                                mailing,
                                i
                              ) {
                                return _c(
                                  "v-tooltip",
                                  {
                                    key: i,
                                    attrs: { top: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            return [
                                              _c(
                                                "v-icon",
                                                _vm._g(
                                                  {
                                                    attrs: {
                                                      color: _vm.$_dashMixin_getMailingIconColor(
                                                        mailing
                                                      ),
                                                      left: ""
                                                    }
                                                  },
                                                  on
                                                ),
                                                [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(
                                                        _vm.$_dashMixin_getMailingIcon(
                                                          mailing
                                                        )
                                                      ) +
                                                      "\n                "
                                                  )
                                                ]
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(mailing.statusDisplay))
                                    ])
                                  ]
                                )
                              }),
                              _vm.campaignModel.numberOfBroadcasts >=
                                _vm.campaignModel.mailings.length &&
                              !_vm.campaignModel.campaignExpired
                                ? _vm._l(
                                    _vm.campaignModel.numberOfBroadcasts -
                                      _vm.campaignModel.mailings.length,
                                    function(i) {
                                      return _c(
                                        "v-tooltip",
                                        {
                                          key: "empty-" + i,
                                          attrs: { top: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var on = ref.on
                                                  return [
                                                    _c(
                                                      "v-icon",
                                                      _vm._g(
                                                        {
                                                          attrs: {
                                                            color: "grey",
                                                            left: ""
                                                          }
                                                        },
                                                        on
                                                      ),
                                                      [
                                                        _vm._v(
                                                          "\n                    mdi-email-outline\n                  "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        },
                                        [_c("span", [_vm._v("Not Created")])]
                                      )
                                    }
                                  )
                                : _vm._e()
                            ],
                            2
                          ),
                          _c(
                            "td",
                            [
                              !_vm.campaignModel.readOnlyActions
                                ? [
                                    _c(
                                      "v-tooltip",
                                      {
                                        attrs: { top: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var on = ref.on
                                                return [
                                                  _c(
                                                    "v-icon",
                                                    _vm._g(
                                                      {
                                                        staticClass: "mr-2",
                                                        attrs: {
                                                          disabled: !_vm
                                                            .campaignModel
                                                            .canAddMailings,
                                                          color: "success"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.$_dashMixin_addMailing(
                                                              _vm.campaignModel
                                                            )
                                                          }
                                                        }
                                                      },
                                                      on
                                                    ),
                                                    [
                                                      _vm._v(
                                                        "\n                    mdi-email-plus\n                  "
                                                      )
                                                    ]
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          3942129428
                                        )
                                      },
                                      [_c("span", [_vm._v("Add Mailing")])]
                                    ),
                                    _c(
                                      "v-menu",
                                      {
                                        attrs: {
                                          right: "",
                                          "x-offset": "",
                                          origin: "right",
                                          transition: "slide-x-transition"
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var onMenu = ref.on
                                                return [
                                                  _c(
                                                    "v-tooltip",
                                                    {
                                                      attrs: { top: "" },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function(ref) {
                                                              var onTooltip =
                                                                ref.on
                                                              return [
                                                                _c(
                                                                  "v-icon",
                                                                  _vm._g(
                                                                    {
                                                                      staticClass:
                                                                        "mr-2",
                                                                      attrs: {
                                                                        disabled:
                                                                          _vm
                                                                            .campaignModel
                                                                            .campaignStatus !==
                                                                          1,
                                                                        color:
                                                                          "error"
                                                                      }
                                                                    },
                                                                    Object.assign(
                                                                      {},
                                                                      onMenu,
                                                                      onTooltip
                                                                    )
                                                                  ),
                                                                  [
                                                                    _vm._v(
                                                                      "\n                        mdi-close\n                      "
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          "Cancel Campaign"
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          3377732414
                                        )
                                      },
                                      [
                                        _c(
                                          "v-card",
                                          [
                                            _c(
                                              "v-card-text",
                                              { staticClass: "pa-5" },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    "Are you sure you want to cancel this campaign? No scheduled mailings will be sent."
                                                  )
                                                ])
                                              ]
                                            ),
                                            _c("v-divider"),
                                            _c(
                                              "v-card-actions",
                                              [
                                                _c("v-spacer"),
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      color: "tertiary",
                                                      disabled: _vm.isLoading()
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                      Cancel\n                    "
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      color: "error",
                                                      loading: _vm.isLoading()
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.$_dashMixin_cancelCampaign(
                                                          _vm.campaignModel
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                      Yes\n                    "
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-tooltip",
                                      {
                                        attrs: { top: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var on = ref.on
                                                return [
                                                  _c(
                                                    "v-icon",
                                                    _vm._g(
                                                      {
                                                        staticClass: "mr-2",
                                                        attrs: {
                                                          color: "info"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.$_dashMixin_copyCampaign(
                                                              _vm.campaignModel
                                                            )
                                                          }
                                                        }
                                                      },
                                                      on
                                                    ),
                                                    [
                                                      _vm._v(
                                                        "\n                    mdi-content-copy\n                  "
                                                      )
                                                    ]
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          3480116639
                                        )
                                      },
                                      [_c("span", [_vm._v("Copy Campaign")])]
                                    )
                                  ]
                                : _vm._e(),
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { top: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          return [
                                            _c(
                                              "v-icon",
                                              _vm._g(
                                                {
                                                  staticClass: "mr-2",
                                                  attrs: { color: "info" },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.$_dashMixin_campaignHistory(
                                                        _vm.campaignModel
                                                          .campaignId
                                                      )
                                                    }
                                                  }
                                                },
                                                on
                                              ),
                                              [
                                                _vm._v(
                                                  "\n                  mdi-book-open-page-variant\n                "
                                                )
                                              ]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    4036389456
                                  )
                                },
                                [_c("span", [_vm._v("Campaign History")])]
                              ),
                              _vm.campaignModel.campaignStatus ===
                                _vm.$_campaignStatus_Completed &&
                              _vm.campaignModel.campaignFinished &&
                              (_vm.$_global_hasRouteClaims(
                                "CampaignEventsHeatmap"
                              ) ||
                                _vm.$_global_hasRouteClaims("CampaignReport"))
                                ? _c(
                                    "v-tooltip",
                                    {
                                      attrs: { top: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var onTooltip = ref.on
                                              return [
                                                _c(
                                                  "v-menu",
                                                  {
                                                    attrs: { "offset-y": "" },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function(ref) {
                                                            var onMenu = ref.on
                                                            var attrs =
                                                              ref.attrs
                                                            return [
                                                              _c(
                                                                "v-icon",
                                                                _vm._g(
                                                                  _vm._b(
                                                                    {
                                                                      staticClass:
                                                                        "mr-2",
                                                                      attrs: {
                                                                        color:
                                                                          "info"
                                                                      }
                                                                    },
                                                                    "v-icon",
                                                                    attrs,
                                                                    false
                                                                  ),
                                                                  Object.assign(
                                                                    {},
                                                                    onTooltip,
                                                                    onMenu
                                                                  )
                                                                ),
                                                                [
                                                                  _vm._v(
                                                                    "\n                      mdi-chart-box\n                    "
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  },
                                                  [
                                                    _c(
                                                      "v-list",
                                                      [
                                                        _vm.$_global_hasRouteClaims(
                                                          "CampaignEventsHeatmap"
                                                        ) &&
                                                        _vm.$_dashMixin_isGeoDataFileTypeId(
                                                          _vm.campaignModel
                                                            .targetAudienceFileTypeId
                                                        )
                                                          ? _c(
                                                              "v-list-item",
                                                              [
                                                                _c(
                                                                  "v-list-item-title",
                                                                  [
                                                                    _c(
                                                                      "router-link",
                                                                      {
                                                                        attrs: {
                                                                          to: {
                                                                            name:
                                                                              "CampaignEventsHeatmap",
                                                                            params: {
                                                                              campaignId:
                                                                                _vm
                                                                                  .campaignModel
                                                                                  .campaignId
                                                                            }
                                                                          }
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                          Campaign Heatmap\n                        "
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        _vm.$_global_hasRouteClaims(
                                                          "CampaignReport"
                                                        )
                                                          ? _c(
                                                              "v-list-item",
                                                              [
                                                                _c(
                                                                  "v-list-item-title",
                                                                  [
                                                                    _c(
                                                                      "router-link",
                                                                      {
                                                                        attrs: {
                                                                          to: {
                                                                            name:
                                                                              "CampaignReport",
                                                                            params: {
                                                                              campaignId:
                                                                                _vm
                                                                                  .campaignModel
                                                                                  .campaignId
                                                                            }
                                                                          }
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                          Campaign Report\n                        "
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e()
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        3105508754
                                      )
                                    },
                                    [_c("span", [_vm._v("Reporting")])]
                                  )
                                : _vm._e(),
                              _vm.isMMSAdmin
                                ? _c(
                                    "v-tooltip",
                                    {
                                      attrs: { top: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var onTooltip = ref.on
                                              return [
                                                _c(
                                                  "v-menu",
                                                  {
                                                    attrs: {
                                                      right: "",
                                                      "x-offset": "",
                                                      origin: "right",
                                                      transition:
                                                        "slide-x-transition",
                                                      "close-on-content-click": false,
                                                      "close-on-click": false
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function(ref) {
                                                            var onMenu = ref.on
                                                            return [
                                                              _c(
                                                                "v-icon",
                                                                _vm._g(
                                                                  {
                                                                    staticClass:
                                                                      "mr-2",
                                                                    attrs: {
                                                                      color:
                                                                        "info"
                                                                    }
                                                                  },
                                                                  Object.assign(
                                                                    {},
                                                                    onMenu,
                                                                    onTooltip
                                                                  )
                                                                ),
                                                                [
                                                                  _vm._v(
                                                                    "\n                      mdi-information\n                    "
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                    model: {
                                                      value:
                                                        _vm.campaignModel
                                                          .adminDetailsMenu,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.campaignModel,
                                                          "adminDetailsMenu",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "campaignModel.adminDetailsMenu"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "v-card",
                                                      [
                                                        _c(
                                                          "v-card-text",
                                                          {
                                                            staticClass:
                                                              "px-5 py-2"
                                                          },
                                                          [
                                                            _c("LabelValue", {
                                                              attrs: {
                                                                label:
                                                                  "Campaign Id",
                                                                value:
                                                                  _vm
                                                                    .campaignModel
                                                                    .campaignId
                                                              }
                                                            }),
                                                            _c("LabelValue", {
                                                              attrs: {
                                                                label:
                                                                  "Target Id",
                                                                value:
                                                                  _vm
                                                                    .campaignModel
                                                                    .targetAudienceId
                                                              }
                                                            }),
                                                            _c("v-divider"),
                                                            _vm._l(
                                                              _vm.campaignModel
                                                                .mailings,
                                                              function(
                                                                mailing,
                                                                i
                                                              ) {
                                                                return _c(
                                                                  "div",
                                                                  {
                                                                    key:
                                                                      mailing.id +
                                                                      "-" +
                                                                      i
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "LabelValue",
                                                                      {
                                                                        attrs: {
                                                                          label:
                                                                            "Mailing Id " +
                                                                            mailing.sequence,
                                                                          value:
                                                                            mailing.id
                                                                        }
                                                                      }
                                                                    ),
                                                                    _vm._l(
                                                                      mailing.splits,
                                                                      function(
                                                                        split,
                                                                        s
                                                                      ) {
                                                                        return _c(
                                                                          "LabelValue",
                                                                          {
                                                                            key:
                                                                              split.id,
                                                                            attrs: {
                                                                              label:
                                                                                "Split Id " +
                                                                                _vm
                                                                                  .$_splitMixin_splitLetters[
                                                                                  s
                                                                                ],
                                                                              value:
                                                                                split.id
                                                                            }
                                                                          }
                                                                        )
                                                                      }
                                                                    ),
                                                                    _c(
                                                                      "v-divider"
                                                                    )
                                                                  ],
                                                                  2
                                                                )
                                                              }
                                                            )
                                                          ],
                                                          2
                                                        ),
                                                        _c(
                                                          "v-card-actions",
                                                          [
                                                            _c("v-spacer"),
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                attrs: {
                                                                  color:
                                                                    "tertiary"
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    _vm.campaignModel.adminDetailsMenu = false
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                        Close\n                      "
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        473858828
                                      )
                                    },
                                    [_c("span", [_vm._v("Admin Details")])]
                                  )
                                : _vm._e()
                            ],
                            2
                          )
                        ]),
                        _c("tr", [
                          _c(
                            "td",
                            {
                              staticClass: "px-0",
                              attrs: { colspan: _vm.campaignHeaders.length }
                            },
                            [
                              _c("CampaignDashboardExpanded", {
                                attrs: {
                                  campaign: _vm.campaignModel,
                                  "current-mailing-id": _vm.currentMailingId,
                                  loading: _vm.campaignLoading,
                                  "hide-target-data": _vm.hideTargetData
                                }
                              })
                            ],
                            1
                          )
                        ])
                      ])
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              2266547975
            )
          })
        : _c("v-skeleton-loader", { attrs: { type: "table" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }